import React from 'react';
import ReactDOM from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';
import { ConfigProvider } from 'antd';
import { BugSnagErrorBoundary } from '@/bugsnag';
import App from '@/entrypoints/App';

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <ErrorBoundary fallback={<div>"hello word</div>}>
    <BugSnagErrorBoundary>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#F4A935',
          },
        }}
      >
        <App />
      </ConfigProvider>
    </BugSnagErrorBoundary>
  </ErrorBoundary>,
);
